<template>
    <div class="base-box">
        
        <el-form ref="setterInfoform" :model="setterInfo" label-width="140px" :rules="rules">
            <el-form-item label="账户类型">
                <el-select v-model="setterInfo.accountType" placeholder="请选择账户类型" @change="getAccountType" :disabled="disabledChange">
                    <el-option label="对公结算" value="1"></el-option>
                    <el-option label="对私结算" value="2"></el-option>
                    <el-option label="非法人对私结算" value="3"></el-option>
                </el-select>
            </el-form-item>
            <div class="images-box" v-if="setterInfo.accountType!='1'">
                <div class="images-item">
                    <el-form-item prop="accountImg">{{accountImg}}
                        <OssUpload @change="getAccountImg" suffix="gif;jpg;png;jpeg" ocrType="BANK_CARD" :isUpdate="isUpdate" :oldImg="setterInfo.accountImg" :isOneImg="true" key="img1"></OssUpload>
                        <div class="images-title"><span>*</span>结算卡照片</div>
                    </el-form-item> 
                </div>
                <div class="images-item">
                    <el-form-item prop="settleIdCardFaceImg" label-width="0">
                        <OssUpload @change="getIdCardFace" suffix="gif;jpg;png;jpeg" ocrType="ID_CARD_FRONT" :isUpdate="isUpdate" :oldImg="setterInfo.settleIdCardFaceImg" :isOneImg="true" key="img2"></OssUpload>
                        <div class="images-title"><span>*</span>结算人身份证正面</div>
                    </el-form-item> 
                </div>
                <div class="images-item">
                    <el-form-item prop="settleIdCardNationalEmblemImg" label-width="0">
                        <OssUpload @change="getIdCardNationalEmblem" suffix="gif;jpg;png;jpeg" ocrType="ID_CARD_BACK" :isUpdate="isUpdate" :oldImg="setterInfo.settleIdCardNationalEmblemImg" :isOneImg="true" key="img3"></OssUpload>
                        <div class="images-title"><span>*</span>结算人身份证反面</div>
                    </el-form-item> 
                </div>
                <div class="images-item" v-if="setterInfo.accountType=='3'">
                    <el-form-item prop="unLegalAuthorizeImg" label-width="0">
                        <OssUpload @change="getUnLegalAuthorizeImg" suffix="gif;jpg;png;jpeg"  :isUpdate="isUpdate" :oldImg="setterInfo.unLegalAuthorizeImg" :isOneImg="true" key="img4"></OssUpload>
                        <div class="images-title"><span>*</span>非法人结算授权书</div>
                    </el-form-item> 
                </div>
            </div>
            
            <div class="images-box" v-else>
                <div class="images-item">
                    <el-form-item prop="accountPermitImg">
                        <OssUpload @change="getAccountPermitImg" suffix="gif;jpg;png;jpeg"  :isUpdate="isUpdate" :oldImg="setterInfo.accountPermitImg" :isOneImg="true" key="img5"></OssUpload>
                        <div class="images-title"><span>*</span>开户许可证</div>
                    </el-form-item> 
                </div>
                <div class="images-item">
                    <el-form-item prop="legalIdCardFaceImg">
                        <OssUpload @change="getLegalIdCardFaceImg" suffix="gif;jpg;png;jpeg" ocrType="ID_CARD_FRONT" :isUpdate="isUpdate" :oldImg="setterInfo.legalIdCardFaceImg" :isOneImg="true" key="img6"></OssUpload>
                        <div class="images-title"><span>*</span>法人身份证正面</div>
                    </el-form-item> 
                </div>
                <div class="images-item">
                    <el-form-item prop="legalIdCardNationalEmblemImg">
                        <OssUpload @change="getLegalIdCardNationalEmblemImg" suffix="gif;jpg;png;jpeg" ocrType="ID_CARD_BACK" :isUpdate="isUpdate" :oldImg="setterInfo.legalIdCardNationalEmblemImg" :isOneImg="true" key="img7"></OssUpload>
                        <div class="images-title"><span>*</span>法人身份证反面</div>
                    </el-form-item> 
                </div>
            </div>
            <el-form-item label="开户名" prop="accountName">
                <el-col :span="12"><el-input type="text" v-model="setterInfo.accountName"></el-input></el-col>
            </el-form-item>
            <el-form-item label="结算账户" prop="accountNo">
                <el-col :span="12"><el-input type="tel" v-model="setterInfo.accountNo"></el-input></el-col>
            </el-form-item>
            <el-form-item label="开户行" prop="bankName">
                <el-select style="width:400px"
                    v-model="setterInfo.bankName"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入开户银行"
                    :remote-method="remoteMethod"
                    @change="getBank"
                    :loading="loading">
                    <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="开户行地区">
                <div style="float:left">
                    <el-form-item prop="accountProvince">
                        <el-select v-model="setterInfo.accountProvince" placeholder="请选择省份" @change="provinceChange">
                            <el-option v-for="item in provinceCityArea" :key="item.code" :label="item.name" :value="item.code" ></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div style="float:left; padding:0 10px" :span="1"> - </div>
                <div style="float:left">
                    <el-form-item prop="accountCity">
                        <el-select v-model="setterInfo.accountCity" placeholder="请选择城市">
                            <el-option v-for="item in cityList" :key="item.code" :label="item.name" :value="item.code" ></el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </el-form-item>
            <el-form-item label="开户支行" prop="branchName">
                <el-select style="width:400px"
                    @focus="checkPC"
                    v-model="setterInfo.branchName"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入支行名称"
                    :remote-method="branchRemoteMethod"
                    @change="getCnaps"
                    :loading="loading">
                    <el-option
                    v-for="item in branchOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="结算人身份证号" prop="settleIdCard"  v-if="setterInfo.accountType!='1'">
                <el-col :span="12"><el-input type="text" v-model="setterInfo.settleIdCard"></el-input></el-col>
            </el-form-item>
            <el-form-item label="银行预留手机号" prop="accountMobileNo">
                <el-col :span="12"><el-input maxlength="11" v-model="setterInfo.accountMobileNo"></el-input></el-col>
            </el-form-item>
            <el-form-item label="身份证有效期" prop="settleIdCardPeriod"  v-if="setterInfo.accountType!='1'">
                <el-col :span="12">
                    <el-date-picker placeholder="选择日期" v-model="setterInfo.settleIdCardPeriod" value-format="yyyy-MM-dd" :disabled="foreverSettleCheck" type="date" style="width: 100%;"></el-date-picker>
                </el-col>
                <el-col :span="5" style="padding-left:20px">
                    <el-checkbox v-model="foreverSettleCheck" @change="setterInfo.settleIdCardPeriod='0'">永久有效</el-checkbox>
                </el-col>
                
            </el-form-item>
            <el-form-item label="法人身份证号" prop="legalIdCard" v-if="setterInfo.accountType=='1' || setterInfo.accountType=='3'">
                <el-col :span="12"><el-input type="tel" v-model="setterInfo.legalIdCard"></el-input></el-col>
            </el-form-item>
            <el-form-item label="法人身份证有效期" prop="legalCardPeriod" v-if="setterInfo.accountType=='1' || setterInfo.accountType=='3'">
                <el-col :span="12">
                    <el-date-picker placeholder="选择日期" v-model="setterInfo.legalCardPeriod" value-format="yyyy-MM-dd" :disabled="foreverCheck" type="date" style="width: 100%;"></el-date-picker>
                </el-col>
                <el-col :span="5" style="padding-left:20px">
                    <el-checkbox v-model="foreverCheck" @change="setterInfo.legalCardPeriod='0'">永久有效</el-checkbox>
                </el-col>
                
            </el-form-item>
            <el-form-item label="驳回原因" v-if="checkMsg">
                <el-input type="textarea" v-model="checkMsg"></el-input>
            </el-form-item>
        </el-form>
        <el-row :gutter="20">
            <el-col :span="12" :offset="6">
                <el-button  type="primary" @click="prevClick">上一步</el-button>
                <el-button type="primary"  @click="nextClick('setterInfoform')">下一步</el-button>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import {formatOCRDate } from '@/utils/tools'
import { CommonApi } from '@/api'
  export default {
    data() {
        var checkAccountImg  = (rule, value, callback) => {
            if (!this.setterInfo.accountImg) {
            return callback(new Error('请上传结算卡照片'));
            } else{
                value = this.setterInfo.accountImg
                callback();
            }
        };
        var checkSettleIdCardFaceImg  = (rule, value, callback) => {
            if (!this.setterInfo.settleIdCardFaceImg) {
            return callback(new Error('请上传结算人身份证正面'));
            } else{
                value = this.setterInfo.settleIdCardFaceImg
                callback();
            }
        };
        var checkSettleIdCardNationalEmblemImg  = (rule, value, callback) => {
            if (!this.setterInfo.settleIdCardNationalEmblemImg) {
            return callback(new Error('请上传结算人身份证反面'));
            } else{
                value = this.setterInfo.settleIdCardNationalEmblemImg
                callback();
            }
        };
        var checkUnLegalAuthorizeImg  = (rule, value, callback) => {
            if (!this.setterInfo.unLegalAuthorizeImg) {
            return callback(new Error('请上传非法人结算授权书'));
            } else{
                value = this.setterInfo.unLegalAuthorizeImg
                callback();
            }
        };
        var checkAccountPermitImg  = (rule, value, callback) => {
            if (!this.setterInfo.accountPermitImg) {
            return callback(new Error('请上传开户许可证'));
            } else{
                value = this.setterInfo.accountPermitImg
                callback();
            }
        };
        var checkLegalIdCardFaceImg  = (rule, value, callback) => {
            if (!this.setterInfo.legalIdCardFaceImg) {
            return callback(new Error('请上传法人身份证正面'));
            } else{
                value = this.setterInfo.legalIdCardFaceImg
                callback();
            }
        };
        var checkLegalIdCardNationalEmblemImg  = (rule, value, callback) => {
            if (!this.setterInfo.legalIdCardNationalEmblemImg) {
            return callback(new Error('请上传法人身份证反面'));
            } else{
                value = this.setterInfo.legalIdCardNationalEmblemImg
                callback();
            }
        };
        var checkDate = (rule, value, callback) => {
            if (!value&&!this.foreverCheck) {
            return callback(new Error('有效期不能为空'));
            } else{
                callback();
                
            }
        };
        var checkSettleDate = (rule, value, callback) => {
            if (!value&&!this.foreverSettleCheck) {
            return callback(new Error('有效期不能为空'));
            } else{
                callback();
                
            }
        };
      return {
        setterInfo: {accountType:'1',accountCity:'',accountName:'',branchName:''},
        foreverCheck: false,
        foreverSettleCheck: false,
        disabledChange: false,
        options:'',
        branchOptions:'',
        rules: {
          accountImg:[{ validator : checkAccountImg, trigger: 'change' }],
          settleIdCardFaceImg:[{ validator : checkSettleIdCardFaceImg, trigger: 'change' }],
          settleIdCardNationalEmblemImg:[{ validator : checkSettleIdCardNationalEmblemImg, trigger: 'change' }],
          unLegalAuthorizeImg:[{ validator : checkUnLegalAuthorizeImg, trigger: 'change' }],
          accountPermitImg:[{ validator : checkAccountPermitImg, trigger: 'change' }],
          legalIdCardFaceImg:[{ validator : checkLegalIdCardFaceImg, trigger: 'change' }],
          legalIdCardNationalEmblemImg:[{ validator : checkLegalIdCardNationalEmblemImg, trigger: 'change' }],
          accountName: [{ required: true, message: '请输入开户名', trigger: 'blur' }],
          accountNo: [{ required: true, message: '请输入结算账户', trigger: 'blur' }],
          accountProvince: [{ required: true, message: '请选择省份', trigger: 'change' }],
          accountCity: [{ required: true, message: '请选择城市', trigger: 'change' } ],
          branchName: [{ required: true, message: '请选择开户支行', trigger: 'change' } ],
          bankName: [{ required: true, message: '请输入开户银行', trigger: 'blur' }],
          settleIdCard: [{ required: true, message: '请输入结算人身份证号', trigger: 'blur' }],
          settleIdCardPeriod: [{validator:checkSettleDate, trigger: 'blur' }],
          legalIdCard: [{ required: true, message: '请输入法人身份证号', trigger: 'blur' }],
          accountMobileNo: [{ required: true, message: '请输入银行预留手机号', trigger: 'blur' }],
          legalCardPeriod: [{ validator:checkDate, trigger: 'blur' }],
        }
      }
    },
    watch:{
        baseData:{
            handler(val){
                if(val&&this.isUpdate){
                    this.setterInfo = val
                    for(let i=0;i<this.provinceCityArea.length;i++){
                        if(val.accountProvince == this.provinceCityArea[i].code){
                            this.cityList = this.provinceCityArea[i].children
                            return
                        }
                    }
                }
            },
            immediate: true
        },
        merType:{
            handler(newVal,oldVal){
                if(newVal!=oldVal){
                    this.disabledChange = newVal == '1' ? true : false
                    if(newVal == '1'){
                        this.disabledChange = true
                        this.setterInfo.accountType = '2'
                    }
                }
            },
            immediate: true
        }
    },
    created(){
        
        
    },
    props:{
        provinceCityArea:{
            type: Array,
            default: []
        },
        isUpdate:{
            type: Boolean,
            default: false
        },
        baseData:{
            type: Object,
            default:{}
        },
        merType:{
            type: String,
            default:'1'
        },
        checkMsg:{
            type: String,
            default:''
        }
    },
    methods: {
        getAccountImg(fileList,filename,ocrData){
            if(JSON.stringify(ocrData) != '{}'){
                this.$set(this.setterInfo,'accountNo',ocrData.bank_card_number.replace(/\s*/g,""))
                this.$set(this.setterInfo,'bankName',ocrData.bank_name)
                if(ocrData.bank_name){
                    this.remoteMethod(ocrData.bank_name,'ocr')
                }
            }
            this.setterInfo.accountImg = filename
        },
        getIdCardFace(fileList,filename,ocrData){
            if(JSON.stringify(ocrData) != '{}'){
                this.$set(this.setterInfo,'settleIdCard',ocrData['公民身份号码'].words)
            }
            this.setterInfo.settleIdCardFaceImg = filename
        },
        getIdCardNationalEmblem(fileList,filename,ocrData){
            if(JSON.stringify(ocrData) != '{}'){
                this.foreverSettleCheck = false
                this.$set(this.setterInfo,'settleIdCardPeriod',formatOCRDate(ocrData['失效日期'].words))
            }
            this.setterInfo.settleIdCardNationalEmblemImg = filename
        },
        getUnLegalAuthorizeImg(fileList,filename,ocrData){
            var file = filename.slice(-1)
            this.setterInfo.unLegalAuthorizeImg = filename
        },
        getAccountPermitImg(fileList,filename){
            var file = filename.slice(-1)
            this.setterInfo.accountPermitImg = filename
        },
        getLegalIdCardFaceImg(fileList,filename,ocrData){
            if(JSON.stringify(ocrData) != '{}'){
                this.$set(this.setterInfo,'legalIdCard',ocrData['公民身份号码'].words)
            }
            this.setterInfo.legalIdCardFaceImg = filename
        },
        getLegalIdCardNationalEmblemImg(fileList,filename,ocrData){
            if(JSON.stringify(ocrData) != '{}'){
                this.foreverCheck = false
                this.$set(this.setterInfo,'legalCardPeriod',formatOCRDate(ocrData['失效日期'].words))
            }
            this.setterInfo.legalIdCardNationalEmblemImg = filename
        },
        nextClick(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$emit('change',this.setterInfo,'next')
                } else {
                    return false;
                }
            });
        },
        prevClick(){
            this.$emit('change','','prev')
        },
        provinceChange(val){
            this.setterInfo.accountCity = ''
            this.provinceCityArea.map(e=>{
                if(val == e.code ){
                    this.cityList = e.children
                }
            })
        },
        getAccountType(){
           this.$forceUpdate()
        },
        async remoteMethod(query,ocr){
            if(query!=''){
               let res = await CommonApi.bankList({name:query})
               if(res.success){
                   this.options = res.data
                   if(ocr){
                       this.setterInfo.bankName = this.options[0].name
                       this.setterInfo.bankCode = this.options[0].code
                       this.setterInfo.branchName = ''
                       this.setterInfo.branchNo = ''
                       this.branchOptions = []
                   }
               }
            } else{
                this.options = []
            }
        },
        async branchRemoteMethod(query){
            if(query!=''){
               let params = {
                provinceCode: this.setterInfo.accountProvince,     
                cityCode: this.setterInfo.accountCity,     
                bankCode: this.setterInfo.bankCode,     
                name:query,     
                }
               let res = await CommonApi.branchList(params)
               if(res.success){
                   this.branchOptions = res.data
               }
            } else{
                this.branchOptions = []
            }
        },
        checkPC(){
            if(!this.setterInfo.accountCity){
                this.Message.error('开户行城市不能为空')
                return
            }
            if(!this.setterInfo.bankName){
                this.Message.error('开户银行不能为空')
                return
            }
        },
        getCnaps(e){
            this.setterInfo.branchName = e.name
            this.setterInfo.branchNo = e.code
        },
        getBank(e){
            this.setterInfo.bankName = e.name
            this.setterInfo.bankCode = e.code
            this.setterInfo.branchName = ''
            this.setterInfo.branchNo = ''
            this.branchOptions = []
        },
    },
  }
</script>