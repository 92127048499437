<template>
    <div class="base-box">
        <el-form ref="imgInfoform" :model="imgInfo" label-width="140px" :rules="rules">
            <el-form-item label="对私结算"  v-if="accountType=='2'">
                <div class="images-box">
                    <div class="images-item" v-if="merType=='2' || merType=='3'">
                        <el-form-item prop="doorImg">
                            <OssUpload @change="getDoorImg" :oldImg="imgInfo.doorImg" suffix="gif;jpg;png;jpeg"  :isUpdate="isUpdate" :isOneImg="true" key="img2"></OssUpload>
                            <div class="images-title"><span>*</span>门头照片</div>
                        </el-form-item> 
                    </div>
                    <div class="images-item" v-if="merType=='2' || merType=='3'">
                        <el-form-item prop="cashierImg">
                            <OssUpload @change="getCashierImg" :oldImg="imgInfo.cashierImg" suffix="gif;jpg;png;jpeg"  :isUpdate="isUpdate" :isOneImg="true" key="img3"></OssUpload>
                            <div class="images-title"><span>*</span>收银台照片</div>
                        </el-form-item> 
                    </div>
                    <div class="images-item" v-if="merType=='2' || merType=='3'">
                        <el-form-item prop="premisesImg">
                            <OssUpload @change="getPremisesImg" :oldImg="imgInfo.premisesImg" suffix="gif;jpg;png;jpeg"  :isUpdate="isUpdate" :isOneImg="true" key="img4"></OssUpload>
                            <div class="images-title"><span>*</span>经营场所照片</div>
                        </el-form-item> 
                    </div>
                    <div class="images-item">
                        <el-form-item prop="holdIdCardImg">
                            <OssUpload @change="getHoldIdCardImg" :oldImg="imgInfo.holdIdCardImg" suffix="gif;jpg;png;jpeg"  :isUpdate="isUpdate" :isOneImg="true" key="img1"></OssUpload>
                            <div class="images-title"><span>*</span>手持身份证照片</div>
                        </el-form-item> 
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="对公结算" v-if="accountType=='1'">
                <div class="images-box">
                    <div class="images-item">
                        <el-form-item prop="doorImg">
                            <OssUpload @change="getDoorImg" :oldImg="imgInfo.doorImg" suffix="gif;jpg;png;jpeg"  :isUpdate="isUpdate" :isOneImg="true" key="img2"></OssUpload>
                            <div class="images-title"><span>*</span>门头照片</div>
                        </el-form-item> 
                    </div>
                    <div class="images-item">
                        <el-form-item prop="cashierImg">
                            <OssUpload @change="getCashierImg" :oldImg="imgInfo.cashierImg" suffix="gif;jpg;png;jpeg"  :isUpdate="isUpdate" :isOneImg="true" key="img3"></OssUpload>
                            <div class="images-title"><span>*</span>收银台照片</div>
                        </el-form-item> 
                    </div>
                    <div class="images-item">
                        <el-form-item prop="premisesImg">
                            <OssUpload @change="getPremisesImg" :oldImg="imgInfo.premisesImg" suffix="gif;jpg;png;jpeg"  :isUpdate="isUpdate" :isOneImg="true" key="img4"></OssUpload>
                            <div class="images-title"><span>*</span>经营场所照片</div>
                        </el-form-item> 
                    </div>
                    <div class="images-item">
                        <el-form-item prop="holdIdCardImg">
                            <OssUpload @change="getHoldIdCardImg" :oldImg="imgInfo.holdIdCardImg" suffix="gif;jpg;png;jpeg"  :isUpdate="isUpdate" :isOneImg="true" key="img1"></OssUpload>
                            <div class="images-title"><span>*</span>手持身份证照片</div>
                        </el-form-item> 
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="非法人对私结算" v-if="accountType=='3'">
                <div class="images-box">
                    <div class="images-item">
                        <el-form-item prop="legalIdCardFaceImg">
                            <OssUpload @change="getLegalIdCardFaceImg" :oldImg="imgInfo.legalIdCardFaceImg" suffix="gif;jpg;png;jpeg"  :isUpdate="isUpdate" :isOneImg="true" key="img6"></OssUpload>
                            <div class="images-title"><span>*</span>法人身份证正面照片</div>
                        </el-form-item> 
                    </div>
                    <div class="images-item">
                        <el-form-item prop="legalIdCardNationalEmblemImg">
                            <OssUpload @change="getLegalIdCardNationalEmblemImg" :oldImg="imgInfo.legalIdCardNationalEmblemImg" suffix="gif;jpg;png;jpeg"  :isUpdate="isUpdate" :isOneImg="true" key="img7"></OssUpload>
                            <div class="images-title"><span>*</span>法人身份证反面照片</div>
                        </el-form-item> 
                    </div>
                    <div class="images-item">
                        <el-form-item prop="holdIdCardImg">
                            <OssUpload @change="getHoldIdCardImg" :oldImg="imgInfo.holdIdCardImg" suffix="gif;jpg;png;jpeg"  :isUpdate="isUpdate" :isOneImg="true" key="img8"></OssUpload>
                            <div class="images-title"><span>*</span>手持身份证照片</div>
                        </el-form-item> 
                    </div>
                </div>
                <div class="images-box">
                    <div class="images-item">
                        <el-form-item prop="doorImg">
                            <OssUpload @change="getDoorImg" suffix="gif;jpg;png;jpeg"  :isUpdate="isUpdate" :oldImg="imgInfo.doorImg" :isOneImg="true" key="img9"></OssUpload>
                            <div class="images-title"><span>*</span>门头照片</div>
                        </el-form-item> 
                    </div>
                    <div class="images-item">
                        <el-form-item prop="cashierImg">
                            <OssUpload @change="getCashierImg" suffix="gif;jpg;png;jpeg"  :isUpdate="isUpdate" :oldImg="imgInfo.cashierImg" :isOneImg="true" key="img10"></OssUpload>
                            <div class="images-title"><span>*</span>收银台照片</div>
                        </el-form-item> 
                    </div>
                    <div class="images-item">
                        <el-form-item prop="premisesImg">
                            <OssUpload @change="getPremisesImg" suffix="gif;jpg;png;jpeg"  :isUpdate="isUpdate" :oldImg="imgInfo.premisesImg" :isOneImg="true" key="img11"></OssUpload>
                            <div class="images-title"><span>*</span>经营场所照片</div>
                        </el-form-item> 
                    </div>
                </div>
            </el-form-item>
        </el-form>
        <el-row :gutter="20">
            <el-col :span="12" :offset="6">
                <el-button  type="primary" @click="prevClick">上一步</el-button>
                <el-button type="primary"  @click="nextClick('imgInfoform')">提交</el-button>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data(){
        var checkLegalIdCardFaceImg  = (rule, value, callback) => {
            if (!this.imgInfo.legalIdCardFaceImg) {
            return callback(new Error('请上传法人身份证正面'));
            } else{
                value = this.imgInfo.legalIdCardFaceImg
                callback();
            }
        };
        var checkLegalIdCardNationalEmblemImg  = (rule, value, callback) => {
            if (!this.imgInfo.legalIdCardNationalEmblemImg) {
            return callback(new Error('请上传法人身份证反面'));
            } else{
                value = this.imgInfo.legalIdCardNationalEmblemImg
                callback();
            };
        }
        var checkHoldIdCardImg  = (rule, value, callback) => {
            if (!this.imgInfo.holdIdCardImg) {
            return callback(new Error('请上传手持身份证照片'));
            } else{
                value = this.imgInfo.holdIdCardImg
                callback();
            };
        }
        var checkDoorImg  = (rule, value, callback) => {
            if (!this.imgInfo.doorImg) {
            return callback(new Error('请上传门头照片'));
            } else{
                value = this.imgInfo.doorImg
                callback();
            };
        }
        var checkCashierImg  = (rule, value, callback) => {
            if (!this.imgInfo.cashierImg) {
            return callback(new Error('请上传收银台照片'));
            } else{
                value = this.imgInfo.cashierImg
                callback();
            };
        }
        var checkPremisesImg  = (rule, value, callback) => {
            if (!this.imgInfo.premisesImg) {
            return callback(new Error('请上传经营场所照片'));
            } else{
                value = this.imgInfo.premisesImg
                callback();
            };
        }
        var checkEntryHomeTreatyImg  = (rule, value, callback) => {
            if (!this.imgInfo.entryHomeTreatyImg) {
            return callback(new Error('请上传入户协议照片'));
            } else{
                value = this.imgInfo.entryHomeTreatyImg
                callback();
            };
        }
        var checkSettleHoldIdCardImg = (rule, value, callback) => {
            if (!this.imgInfo.settleHoldIdCardImg) {
            return callback(new Error('请上传手持身份证照片'));
            } else{
                value = this.imgInfo.settleHoldIdCardImg
                callback();
            };
        }
        return{
            imgInfo:{},
            rules: {
                legalIdCardFaceImg:[{ validator : checkLegalIdCardFaceImg, trigger: 'change' }],
                legalIdCardNationalEmblemImg:[{ validator : checkLegalIdCardNationalEmblemImg, trigger: 'change' }],
                holdIdCardImg:[{ validator : checkHoldIdCardImg, trigger: 'change' }],
                doorImg:[{ validator : checkDoorImg, trigger: 'change' }],
                cashierImg:[{ validator : checkCashierImg, trigger: 'change' }],
                premisesImg:[{ validator : checkPremisesImg, trigger: 'change' }],
                settleHoldIdCardImg:[{ validator : checkSettleHoldIdCardImg, trigger: 'change' }],
            }
        }
    },
    props:{
        accountType:{
            type: String,
            default:'1'
        },
        merType:{
            type: String,
            default:'1'
        },
        isUpdate:{
            type: Boolean,
            default: false
        },
        baseData:{
            type: Object,
            default:{}
        }
    },
    watch:{
        baseData:{
            handler(val){
                if(val&&this.isUpdate){
                    this.imgInfo = val
                }
            },
            immediate: true
            
        }
    },
    methods:{
        getHoldIdCardImg(fileList,filename){
            var file = filename.slice(-1)
            this.imgInfo.holdIdCardImg = filename
        },
        getDoorImg(fileList,filename){
            var file = filename.slice(-1)
            this.imgInfo.doorImg = filename
        },
        getCashierImg(fileList,filename){
            var file = filename.slice(-1)
            this.imgInfo.cashierImg = filename
        },
        getPremisesImg(fileList,filename){
            var file = filename.slice(-1)
            this.imgInfo.premisesImg = filename
        },
        getEntryHomeTreatyImg(fileList,filename){
            var file = filename.slice(-1)
            this.imgInfo.entryHomeTreatyImg = filename
        },
        getLegalIdCardFaceImg(fileList,filename){
            var file = filename.slice(-1)
            this.imgInfo.legalIdCardFaceImg = filename
        },
        getLegalIdCardNationalEmblemImg(fileList,filename){
            var file = filename.slice(-1)
            this.imgInfo.legalIdCardNationalEmblemImg = filename
        },
        getSettleHoldIdCardImg(fileList,filename){
            var file = filename.slice(-1)
            this.imgInfo.settleHoldIdCardImg = filename
        },
        nextClick(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$emit('change',this.imgInfo,'submit')
                } else {
                    return false;
                }
            });
        },
        prevClick(){
            this.$emit('change','','prev')
        },
    }
}
</script>